interface ReclaimitIframeProps {
    height: string | number;
    src: string;
    width: string | number;
}

const ReclaimitIframe = ({ src = '', height = '800px', width = '615px' }: ReclaimitIframeProps) => (
    <iframe
        frameBorder="0"
        height={height}
        id="reclaimitiframe"
        marginHeight={0}
        marginWidth={0}
        scrolling="auto"
        src={src}
        width={width}
    />
);

export default ReclaimitIframe;
